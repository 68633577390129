
.payment-methods-container {
    display flex
    flex-direction column
    gap 16px

    .parent-container {
        .item-container {

            &:not(:first-of-type):not(:last-of-type){

            }

            &:not(.-is-selected):not(:last-of-type) {
                .item {
                    border-bottom: 0;
                }
            }

            &:first-of-type {
                .item {
                    border-radius: 8px 8px 0 0;
                }
            }

            &:last-child:not(:first-child) {
                > .item {
                    border-radius: 0 0 8px 8px;
                }

                &.-is-selected {
                    > .item {
                        border-radius: 0;
                    }

                    .item-desc {
                        border-radius: 0 0 8px 8px;
                    }
                }
            }

            &:first-of-type:last-child {
                .item-desc {
                    border-radius: 0 0 8px 8px;
                }
            }

            &.-is-selected {
                .item {
                    background-color: $color-fill-radio-button;
                    border: 1.2px solid $color-bnn;

                    .checkmark {
                        border: 5.5px solid $color-bnn;
                    }
                }
            }

            &not(:last-child):not(:first-child) {
                .item {
                    border-top 0
                }
            }

            &.-is-selected:not(:last-child){
                .item-desc {
                    border-bottom: none
                    border-top: none
                }
            }

            &.pay-at-store {
                .item {
                    border-radius: 8px;
                }
            }
        }
    }


    .item {
        display: flex;
        align-items: center;
        gap: 11px;
        padding: 11px;

        border: 1.2px solid $color-dapp-gray-3;
        cursor: pointer;

        &.-is-disabled {
            opacity: 0.4;
            cursor: not-allowed;
            pointer-events: none;
        }

        .item-label {
            flex: 1;

            > .label {
                font-weight: 500;
                color: rgba(#000000, 0.70);
            }

            > .description-s {

                font-size: 12px;
                font-weight: 600;
                line-height: 1.3;
                color: rgba(#000000, 0.45);
            }
        }

        .item-icon {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        .checkmark {
            width 16px;
            height 16px;
            border-radius: 50%;
            border: 1px solid #d2d2d7;
            transition: border .1s;
        }

    }

    .base-radio-button {
        position: relative;
        display: block;
        cursor: pointer;
        user-select: none;

        &.-is-disabled {
            cursor: not-allowed;

            > .label {
                color: $color-absolute-black-45;
            }
        }

        // Hide the browser's default radio
        input {
            display: none;
            opacity: 0;
            width: 0;
            height: 0;
            cursor: pointer;
        }

        // Create a custom radio
        .checkmark {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            height:  16px;
            width: 16px;

            display: flex;
            align-items: center;
            justify-content: center;

            background-color: transparent;
            border-radius: 50%;
            border: 1px solid $color-grey83;
            transition: border 0.1s;
        }

        & input:checked ~ .checkmark {
            border: rem(5.5) solid $color-bnn;
        }

        & input:focus ~ .checkmark {
            box-shadow: 0 0 0 rem(3) $color-functionality-secondary;
        }


        & input:not(:checked):disabled ~ .checkmark {
            border: 2px solid $color-grey-light-50;
        }

        & input:checked:disabled ~ .label,
        & input:not(:checked):disabled ~ .label {
            color: $color-grey-light-50;
        }


        & input:checked:disabled ~ .checkmark {
            border: rem(7) solid $color-grey-light-50;
        }
    }

    .item-desc {
        background-color rgba(0, 0, 0, 0.043)
        border 1px solid rgb(222, 222, 222)
        box-sizing border-box
        color rgb(0, 0, 0)
        display block
        font-size 14px
        font-weight 400
        letter-spacing normal
        line-height 21px
        padding 17px

        width 100%
        display grid
        grid-template-columns: repeat(2, 1fr)
        gap: 12px 16px

        @media screen and (min-width: 1024px) {
            grid-template-columns: repeat(3, 1fr)
        }

        @media screen and (min-width: 1280px) {
            grid-template-columns: repeat(4, 1fr)
        }
    }

    .installment-plan-container {
        grid-column span 2
        order: 1

        @media screen and (min-width: 1024px) {
            grid-column span 3
        }

        @media screen and (min-width: 1280px) {
            grid-column span 4
        }
    }
}

.section-summary {
    width 100%
    max-width: 100dvw

    display flex;
    flex-direction: column;
    gap: 32px;
}

.payment-methods-actions-container {
    display flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media screen and (min-width: 768px) {
        justify-content: space-between;
    }
}
