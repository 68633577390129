$color-gray= #52514e
$color-gray-2= #666
$color-gray-light= #ebebe3
$color-green-midnight= #697b70
$color-gold= #ffd6b9
$color-gray-border= #e2e8f0
$color-gray-medium= #f2f2f2
$color-green= #5bba47
$color-green-dark= #5bba48
$color-dark-gray= #434242
$color-dark-gray-medium= #6f6f6f
$color-gray-100= #F5F5F5
$color-gray-200= #d6d6d6
$color-black-100= #272727
$color-red= #FF4252
$color-gray-300= #ececec
$color-blue= #0671e3
$color-gray-400= #eff0f2
$color-bnn= #0071e3
$color-bnn-mid= #0071e373
$color-bnn-light= #0071e30d
$color-bnn-border= #0071e3
$color-bnn-border-2= #0071e3
$color-bnn-border-3= #0071e3
$color-bnn-btn-text= #FFF
$color-deposit= #fff
$color-deposit-text= #0071e3
$color-bnn-gray= #F5F5F7
$color-bnn-blue= #338DF7
$color-bnn-blue-2= #0095CC
$color-bnn-border-btn= #E5E5E5
$color-bnn-border-dark-btn= #A5A5A5
$color-bnn-border-light= #5C5C5C
$color-bnn-red= #ff381d
$color-bnn-red-2= #FF2121
$color-bnn-gray-light= #CCCCCC
$color-bnn-gray-light-2= #F8F8F8
$color-bnn-gray-light-3= #d0d0d0
$color-bnn-gray-medium= #151515
$color-bnn-gray-medium-2= #818181
$color-bnn-orange= #FF6200
$color-bnn-orange-2= #ff8901
$color-btn-base= #fff
$color-text-price= #0071e3
$color-bg-btn-spec= #0071e3
$color-border-btn-spec= #0071e3
$color-text-btn-spec= #fff
$color-text-harry-up= #000
$color-secondary= #0071e3
$color-secondary-text= #0071e3
$color-tertiary= #5bba47
$color-normal-price= rgba(0,0,0,.87)
$color-fourth= transparent
$color-warning= #eba614
$color-timer-primary= #5BB947
// DAPP Design System
$color-dapp-gray-1= #424245
$color-dapp-gray-2= #6E6E73
$color-dapp-gray-3= #D0D0D0
$color-dapp-gray-4= #F2F2F2

$color-gray30= #4d4d4d;
$color-gray44= #677279;
$color-gray45= #757575;
$color-gray49= #7d7d7d;
$color-gray59= #969696;
$color-gray82= #d2d2d2;
$color-gray83= #d2d2d7;
$color-gray84= #d6d6d6;
$color-gray85= #d9d9d9;
$color-gray86= #dedede;
$color-gray91= #e9e9e9;
$color-gray97= #f7f7f7;
$color-gray98= #fafafa;

$color-grey-light-80= #f9f9f9;
$color-grey-light-70= #ddd;
$color-grey-light-60= #bebebe;
$color-grey-light-50= #9d9d9d;

// checkout
$color-fill-radio-button= #f4f6ff;
$color-checkout-select-address= #5f6883;
$color-checkout-collapse-arrow= #f3f8fe;

$font-family-base= 'SF Pro Text', 'SF Pro Display','sukhumvit', 'Sukhumvit Set', 'Prompt', 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif

// TO-DO: Change to 88.625px
$header-height= 88.625px
$header-height-mobile= 62px

small = '(min-width: 320px) and (max-width: 767px)'
medium = '(min-width: 768px) and (max-width: 1024px)'
min-medium = '(min-width: 768px)'
tablet = 'screen and (max-width: 960px)'
medium-landscape = '(min-width: 768px) and (max-width: 1024px) and (orientation: landscape)'
large = '(min-width: 1025px) and (max-width: 1280px)'
xlarge = '(min-width: 1281px) and (max-width: 1440px)'
xxlarge = '(min-width: 1441px)'

@media (min-width: 992px) 
    .gallery-md
        margin-top: 0.5rem
        display flex
        flex-wrap wrap
        width 490px
    .relative-md
        position: relative !important
    .absolute-md
        position: absolute !important    
    .float-left-md
        float: left
    .sticky-md
        position: fixed !important
    .mw-490
        min-width: 80px !important
    .mh-621
        min-height: 142px !important
    .top-md-0 
        top: 20px
    .h-md-2
        height: 0.5rem 
    .pd-0
        padding: none;
    .pd-1
        padding: none; 
    .pdr-0
        padding-right: 0rem !important
    .pdr-1
        padding-right: 0.5rem !important 
    .pdr-2
        padding-right: 1rem !important      
    .pdr-3
        padding-right: 1.2rem !important    
    .pdr-4
        padding-right: 1.5rem !important  
    .pdr-5
        padding-right: 2rem !important  
    .pdr-6
        padding-right 3rem !important             
    .pdl-0
        padding-left: 0rem !important
    .pdl-1
        padding-left: 0.5rem !important  
    .pdl-2
        padding-left: 1rem !important       
    .pdl-3
        padding-left: 1.2rem !important    
    .pdl-4
        padding-left: 1.5rem !important    
    .pdl-5
        padding-left: 2rem !important  
    .pdl-6
        padding-left: 3rem !important        
       
.align-flex-end
    align-items: flex-end       
.justify-content-between
    justify-content: space-between

.text-underline
    text-decoration: underline      
.text-red
    color: $color-red  
.text-blue
    color: $color-blue          
.text-green
    color: $color-green
.text-primary
	color: $color-bnn
.text-primary-mid
	color: $color-bnn-mid
.text-primary-light
	color: $color-bnn-light
.bg-primary
	background-color: $color-bnn
.bg-primary-mid
	background-color: $color-bnn-mid
.bg-primary-light
	background-color: $color-bnn-light
.text-black
    color: $color-black-100   

.text-pre-order
    color: #336e7b;

.text-line-through
    text-decoration: line-through

.pd-0
    padding: 0 .0em;
.pd-1
    padding: 0 .25em; 

.bg-gray
    background-color: $color-gray

.bg-gray-light
    background-color: $color-gray-light 

.bg-green-midnight
    background-color: $color-green-midnight 

.bg-gold
    background-color: $color-gold          

hr.gray
    background-color:$color-gray-border
    height: 1px
    border: 0

.w-full
    width: 100%
.w-fit-content
	width: fit-content

.font-bold
    font-weight: bold   

.font-light 
  font-weight: 300


.font-normal 
  font-weight: 400


.font-medium 
  font-weight: 500

.fit-contain
  object-fit: contain


.font-semibold 
  font-weight: 600


.text-dark-gray
    color: $color-dark-gray
.text-white
	color: white !important
.text-bnn-blue
    color: $color-bnn-blue
.text-gray-1 {
    color: $color-gray-1
}
.text-gray-2 {
    color: $color-gray-2
}
.text-gray-3 {
    color: $color-gray-3
}
.text-gray-4 {
    color: $color-gray-4
}

// Display
.dp-none {
    display: none
}

.dp-block {
    display: block
}

@media screen and (min-width: 768px) {
    .dp-md-none {
        display: none
    }
    .dp-md-flex {
        display: flex
    }
}

.dp-md-block {
    @media screen and (min-width: 768px) {
        display: block
    }
}

.dp-lg-none {
    @media screen and (min-width: 1024px) {
        display: none
    }
}

.dp-lg-block {
    @media screen and (min-width: 1024px) {
        display: block
    }
}

 .dp-lg-flex {
    display: none !important

    @media screen and (min-width: 1024px) {
        display: flex !important
    }

}

.text-label-summary
   font-size: 1.5rem
.text-cost-summary
   font-size: 1.875rem
@media (max-width: 768px)
 .text-label-summary
    font-size: 1.125rem
 .text-cost-summary
    font-size: 1.325rem  

.ship-date-highlight
    color: $color-red !important
    font-weight: 500 !important
    &.lot-2 {
        color: $color-tertiary !important
    }

.v-toast__item--success
    background:  $color-green-dark !important

.v-btn {
  border-radius: 32px !important;
}

.v-btn.v-size--default {
    height: 50px
    padding: 14px 30px !important;
}

.v-btn.v-btn--outlined {
    border-width: 2px
}

.v-btn.v-btn--text.btn-link {
    height: fit-content;
    padding: 0 !important;
    font-weight: 600;
    line-height: 1.125rem;
    letter-spacing: -.015rem;
    text-decoration: none;

    &:hover {
        background: none;
    }

    .v-icon {
        font-size: 20px
    }
}

.v-btn.v-size--default:not(.btn-link):not(.v-btn--contained) {
    height: 50px
}

.v-btn.v-size--default.btn-apple-care-toggle:not(.btn-link):not(.v-btn--contained){
    width: 75px
    height: 35px
    padding: 6px !important;
    border-width: 1.5px !important;

    &:hover {
        background-color: none;

        &:before {
            background-color: none;
        }
    }
}

.v-btn.v-size--large {
    height: 56px
    padding: 14px 30px !important;
    font-weight: 600
    line-height: 18px
}

.v-dialog:not(.v-dialog--fullscreen) {
    border-radius 18px
}
.v-dialog__content {
    border-radius: 18px !important;
}
.v-text-field--outlined {
  border-radius: 5px !important;
}
.v-dialog__content.v-dialog__content--active {
    backdrop-filter: blur(20px);
}
.v-overlay__scrim {
    background: rgba(0, 0, 0, 0.48);
}
.row {
    margin-top: 0
    margin-bottom: 0    
}

body.freeze-overflow {
  overflow-y: hidden;
}

.full-page {
	width: 100dvw;
    // header + footer
	min-height: calc(100dvh - (62px + 50px));
    margin-top: $header-height-mobile !important;

    overflow-x: hidden

	&.content-center{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
  	}

    @media screen and (min-width: 768px) {
        min-height: calc(100dvh - (92px + 450px));
    }

    @media screen and (min-width: 1024px) {
        min-height: calc(100dvh - (92px + 576px));
        margin-top: $header-height !important;
    }

    @media screen and (min-width: 1440px) {
        min-height: calc(100dvh - (92px + 600px));
    }
}

// DAPP Design System
// Typography
.display-l
	font-family: "SF Pro Display"
	font-size: 48px
	font-style: normal
	font-weight: 600
	line-height: 58px

.display-m
	font-family: "SF Pro Display"
	font-size: 36px
	font-style: normal
	font-weight: 600
	line-height: 42px

.display-s
	font-family: "SF Pro Display"
	font-size: 32px
	font-style: normal
	font-weight: 600
	line-height: 36px

.headline-l
	font-family: "SF Pro Display"
	font-size: 24px
	font-style: normal
	font-weight: 600
	line-height: 30px
	letter-spacing: 0.48px

.headline-sm-l
	@media screen and (max-width: 767px) {
        font-family: "SF Pro Display"
        font-size: 24px
        font-style: normal
        font-weight: 600
        line-height: 30px
        letter-spacing: 0.48px
    }

.headline-m
	font-family: "SF Pro Display"
	font-size: 20px
	font-style: normal
	font-weight: 600
	line-height: 26px
	letter-spacing: -0.02em

.headline-s
	font-family: "SF Pro Display"
	font-size: 18px
	font-style: normal
	font-weight: 600
	line-height: 20px
	letter-spacing: 0.36px

.title-bold
	font-family: "SF Pro Text"
	font-size: 16px
	font-style: normal
	font-weight: 700
	line-height: 20.002px

.title-semibold
	font-family: "SF Pro Text"
	font-size: 16px
	font-style: normal
	font-weight: 600
	line-height: 20.002px

.title-medium
	font-family: "SF Pro Text"
	font-size: 16px
	font-style: normal
	font-weight: 500
	line-height: 20.002px

.title-regular
	font-family: "SF Pro Text"
	font-size: 16px
	font-style: normal
	font-weight: 400
	line-height: 20px

.body-s-semibold
	font-family: "SF Pro Text"
	font-size: 14px
	font-style: normal
	font-weight: 600
	line-height: 20.002px

.body-s-medium
	font-family: "SF Pro Text"
	font-size: 14px
	font-style: normal
	font-weight: 500
	line-height: 16px

.body-s
	font-family: "SF Pro Text"
	font-size: 14px
	font-style: normal
	font-weight: 400
	line-height: normal

.label-l-semibold
	font-family: "SF Pro Text"
	font-size: 12px
	font-style: normal
	font-weight: 600
	line-height: 14px

.label-l-medium
	font-family: "SF Pro Text"
	font-size: 12px
	font-style: normal
	font-weight: 500
	line-height: 16px

.label-l-regular
	font-family: "SF Pro Text"
	font-size: 12px
	font-style: normal
	font-weight: 400
	line-height: 14px

.label-s-semibold
	font-family: "SF Pro Text"
	font-size: 8px
	font-style: normal
	font-weight: 600
	line-height: 14px

.label-s-regular
	font-family: "SF Pro Text"
	font-size: 8px
	font-style: normal
	font-weight: 400
	line-height: 14px

.strikethrough
	font-family: "SF Pro Text"
	font-size: 16px
	font-style: normal
	font-weight: 600
	line-height: normal

.divider-gray-83
    border: none;
    border-top: 1px solid $color-gray83; /* Adjust the color as needed */
    margin: 0; /* Adjust the margin as needed */

.divider-gray-85
    border: none;
    border-top: 1px solid $color-gray85; /* Adjust the color as needed */
    margin: 0; /* Adjust the margin as needed */
